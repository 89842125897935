var data = {
  body: `<g transform="matrix(19.4756,0,0,19.4756,-0.538631,11.39)" fill="currentColor" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g>
        <path d="M0.028,0L1.253,0L1.253,8.132L6.099,8.132L6.099,9.134L0.028,9.134L0.028,0L0.028,0Z"/>
        <path d="M9.441,2.395C10.276,2.284 11.167,2.451 11.836,3.008C12.56,3.509 12.95,4.4 13.005,5.236C13.117,6.238 13.005,7.241 12.448,8.076C11.947,8.744 11.167,9.19 10.276,9.246C9.441,9.357 8.494,9.19 7.77,8.633C7.101,8.076 6.767,7.241 6.656,6.405C6.544,5.514 6.656,4.511 7.213,3.732C7.658,2.952 8.549,2.451 9.441,2.395ZM9.608,3.342C10.165,3.286 10.777,3.453 11.223,3.843C11.613,4.289 11.836,4.846 11.891,5.403C11.947,6.015 11.891,6.684 11.613,7.241C11.334,7.798 10.833,8.188 10.22,8.299C9.663,8.41 9.051,8.243 8.661,7.909C8.215,7.575 7.937,7.074 7.825,6.572C7.714,5.848 7.77,5.124 8.048,4.456C8.327,3.843 8.939,3.398 9.608,3.342L9.608,3.342Z"/>
        <rect x="0.028" y="10.583" width="13.145" height="1.392"/>
    </g>
</g>`,
  width: 256,
  height: 256,
};

export default data;
