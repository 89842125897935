import logo from '../../images/companies_logos/kaplan_logo.svg';

const kaplan = {
  name: 'Kaplan Aspect Bournemouth, UK',
  degree: 'Certificate of Advanced English',
  link:
    'https://www.kaplaninternational.com/united-kingdom/bournemouth/english-school-bournemouth',
  logo,
  from: '2008-01-01',
  to: '2009-01-01',
  fieldOfStudy: 'English Language',
  description: `One-year english language-focused course in England, finished the school with a certificate of Advanced english, FCE certificate`,
};

export default kaplan;
