import React from 'react';
import styled from 'styled-components';
import { prop, pipe, has } from 'ramda';
import { lighten } from 'polished';
import { InlineIcon } from '@iconify/react';

// Individual icons
import ramdaIcon from '@iconify/icons-logos/ramda';
import typescriptIcon from '@iconify/icons-simple-icons/typescript';
import javascriptIcon from '@iconify/icons-simple-icons/javascript';
import nodejsIcon from '@iconify/icons-simple-icons/nodejs';
import htmlIcon from '@iconify/icons-simple-icons/html5';
import cssIcon from '@iconify/icons-simple-icons/css3';
import angularIcon from '@iconify/icons-simple-icons/angular';
import jenkinsIcon from '@iconify/icons-simple-icons/jenkins';
import awsIcon from '@iconify/icons-simple-icons/amazonaws';
import jqueryIcon from '@iconify/icons-simple-icons/jquery';
import jestIcon from '@iconify/icons-simple-icons/jest';
import vscodeIcon from '@iconify/icons-simple-icons/visualstudiocode';
import githubIcon from '@iconify/icons-simple-icons/github';
import sassIcon from '@iconify/icons-simple-icons/sass';
import reactIcon from '@iconify/icons-simple-icons/react';
import storybookIcon from '@iconify/icons-simple-icons/storybook';
import mongodbIcon from '@iconify/icons-simple-icons/mongodb';
import reduxIcon from '@iconify/icons-simple-icons/redux';
import styledcomponentsIcon from '@iconify/icons-simple-icons/styled-components';
import postgresIcon from '@iconify/icons-simple-icons/postgresql';
import gruntIcon from '@iconify/icons-simple-icons/grunt';
import wordpressIcon from '@iconify/icons-simple-icons/wordpress';
import phpIcon from '@iconify/icons-simple-icons/php';
import xmlIcon from '@iconify/icons-fa-solid/code';
import dockerIcon from '@iconify/icons-fa-brands/docker';
import testcafeIcon from '../custom-icons/testcafe';
import cypressIcon from '../custom-icons/cypress';
import lodashIcon from '../custom-icons/lodash';
// End icons

import { getThemeColor } from '../theme';
import { Box } from 'grommet';

const BadgeWrapper = styled(Box)`
  border-radius: 0.2rem;
  background: ${lighten(0.7, getThemeColor('brand-dark-blue'))};
  /* box-shadow: 0 2px 2px -2px ${getThemeColor('brand-gray')}; */
  /* border: 1px solid ${lighten(0.2, getThemeColor('brand-dark-blue'))}; */
  color: ${getThemeColor('brand-dark-blue')};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  padding: 0.3rem 0.4rem;
  margin: 0.3rem 0.4rem 0.3rem 0;
  /* font-size: 1.5rem; */
`;

const technologiesList = {
  javascript: {
    icon: javascriptIcon,
    name: 'JavaScript',
  },
  nodejs: {
    icon: nodejsIcon,
    name: 'NodeJS',
  },
  html: {
    icon: htmlIcon,
    name: 'HTML5',
  },
  css: {
    icon: cssIcon,
    name: 'CSS',
  },
  react: {
    icon: reactIcon,
    name: 'React',
  },
  angularjs: {
    icon: angularIcon,
    name: 'AngularJS',
  },
  jenkins: {
    icon: jenkinsIcon,
    name: 'Jenkins',
  },
  aws: {
    icon: awsIcon,
    name: 'AWS',
  },
  typescript: {
    icon: typescriptIcon,
    name: 'TypeScript',
  },
  jquery: {
    icon: jqueryIcon,
    name: 'jQuery',
  },
  jest: {
    icon: jestIcon,
    name: 'Jest',
  },
  vscode: {
    icon: vscodeIcon,
    name: 'Visual Studio Code',
  },
  github: {
    icon: githubIcon,
    name: 'Visual Studio Code',
  },
  sass: {
    icon: sassIcon,
    name: 'Sass',
  },
  storybook: {
    icon: storybookIcon,
    name: 'Storybook',
  },
  styledcomponents: {
    icon: styledcomponentsIcon,
    name: 'styled components',
  },
  ramda: {
    icon: ramdaIcon,
    name: 'Ramda',
  },
  mongodb: {
    icon: mongodbIcon,
    name: 'MongoDB',
  },
  testcafe: {
    icon: testcafeIcon,
    name: 'Testcafe',
  },
  redux: {
    icon: reduxIcon,
    name: 'Redux',
  },
  cypress: {
    icon: cypressIcon,
    name: 'cypress',
  },
  postgres: {
    icon: postgresIcon,
    name: 'PostgreSQL',
  },
  grunt: {
    icon: gruntIcon,
    name: 'Grunt',
  },
  xml: {
    icon: xmlIcon,
    name: 'XML',
  },
  xslt: {
    icon: xmlIcon,
    name: 'XSLT',
  },
  wordpress: {
    icon: wordpressIcon,
    name: 'Wordpress',
  },
  lodash: {
    icon: lodashIcon,
    name: 'Lodash',
  },
  docker: {
    icon: dockerIcon,
    name: 'Docker',
  },
  php: {
    icon: phpIcon,
    name: 'PHP',
  },
};

const getTech = name => prop(name, technologiesList);
const getIcon = pipe(getTech, prop('icon'));

const getName = pipe(getTech, prop('name'));

export const TechBadge = ({ tech }) => (
  <BadgeWrapper a11yTitle={getName(tech)}>
    {has(tech, technologiesList) ? (
      <>
        <InlineIcon
          icon={getIcon(tech)}
          css={`
            /* height: 1.5rem; */
            margin-right: 0.3rem;
            font-size: 1rem;
          `}
        />
        <span
          css={`
            font-size: 12px;
            line-height: normal;
            font-weight: 500;
            color: ${getThemeColor('brand-gray')};
          `}
        >
          {getName(tech)}
        </span>
      </>
    ) : (
      <span
        css={`
          font-size: 0.8rem;
          line-height: 1.2rem;
          font-weight: 600;
          color: ${getThemeColor('brand-gray')};
        `}
      >
        {tech}
      </span>
    )}
  </BadgeWrapper>
);
