import React from 'react';
import { Grid, ResponsiveContext, Box, Heading } from 'grommet';
import styled, { css } from 'styled-components';
import { getThemeColor } from '../theme';

const responsiveSize = (currentSize, [large, medium = null, small = null]) => {
  if (currentSize === 'small') {
    return small ? small : medium ? medium : large ? large : null;
  }

  if (currentSize === 'medium') {
    return medium ? medium : large ? large : null;
  }

  if (currentSize === 'large') {
    return large ? large : null;
  }
};

const SectionTitle = styled(Heading)`
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 300;
  color: ${getThemeColor('brand-dark-blue')};
  margin-top: 0;
`;

const ResumeSection = ({ sectionName, children }) => {
  const currentSize = React.useContext(ResponsiveContext);

  return (
    <Grid
      columns={[
        responsiveSize(currentSize, ['30%', '30%', '0%']),
        responsiveSize(currentSize, ['70%', '70%', '100%']),
      ]}
      fill="vertical"
      rows={['full']}
      gap={currentSize === 'small' ? 'none' : 'small'}
      areas={[
        { name: 'sidebar', start: [0, 0], end: [0, 0] },
        { name: 'content', start: [1, 0], end: [1, 0] },
      ]}
      as="section"
      margin="1rem"
    >
      <Box
        gridArea="sidebar"
        pad="none"
        css={css`
          display: ${currentSize === 'small' ? 'none' : 'flex'};
          border-right: 2px solid ${getThemeColor('brand-dark-blue')};
          color: ${getThemeColor('brand-gray')};
        `}
      >
        <SectionTitle
          level="2"
          textAlign="end"
          css={`
            background: ${getThemeColor('brand-dark-blue')};
            color: ${getThemeColor('brand-light-gray')};
            padding: 0.2rem 1rem;
            font-weight: 300;
          `}
        >
          {sectionName}
        </SectionTitle>
      </Box>

      <Box gridArea="content" pad="small">
        {currentSize === 'small' && (
          <SectionTitle
            level="2"
            textAlign={currentSize === 'small' ? 'left' : 'center'}
            css={`
              border-bottom: ${currentSize === 'small'
                ? '1px solid' + getThemeColor('brand')
                : 'none'};
            `}
          >
            {sectionName}
          </SectionTitle>
        )}
        {children}
      </Box>
    </Grid>
  );
};

export default ResumeSection;
