import React from 'react';
import { Anchor } from 'grommet';
import logo from '../../images/companies_logos/cnc_200x200.png';

const frontendDev = {
  title: 'Front-end Developer',
  location: 'Prague, CZ',
  from: '2014-09-01',
  to: '2015-12-31',
  technologies: ['javascript', 'react', 'nodejs'],
  description: [
    <>
      Worked on developing front-end for largest media house websites in the
      Czech Republic. Example websites include{' '}
      <Anchor
        href="https://www.blesk.cz/"
        title="Blesk.cz"
        target="_blank"
        rel="noopener"
      >
        Blesk.cz
      </Anchor>
      ,{' '}
      <Anchor
        href="https://www.isport.cz/"
        title="iSport.cz"
        target="_blank"
        rel="noopener"
      >
        isport.cz
      </Anchor>
      ,{' '}
      <Anchor
        href="https://www.reflex.cz/"
        title="Reflex.cz"
        target="_blank"
        rel="noopener"
      >
        Reflex.cz
      </Anchor>
      ,{' '}
      <Anchor
        href="https://www.auto.cz/"
        title="Auto.cz"
        target="_blank"
        rel="noopener"
      >
        Auto.cz
      </Anchor>
      ,{' '}
      <Anchor
        href="https://www.e15.cz/"
        title="E15.cz"
        target="_blank"
        rel="noopener"
      >
        E15.cz
      </Anchor>
      ,{' '}
      <Anchor
        href="https://www.recepty.cz/"
        title="Recepty.cz"
        target="_blank"
        rel="noopener"
      >
        recepty.cz
      </Anchor>
      ,{' '}
      <Anchor
        href="https://www.zive.cz/"
        title="Zive.cz"
        target="_blank"
        rel="noopener"
      >
        zive.cz
      </Anchor>
      , and others.
    </>,
    <>
      Worked on functionalities for the websites using JavaScript and jQuery.
    </>,
    <>
      Worked as a lead developer on a greenfield web application{' '}
      <Anchor
        href="https://app.isportlife.cz/welcome"
        target="_blank"
        rel="noopener"
      >
        Superlife (now called isportlife)
      </Anchor>
      . The app was written in AngularJS, styled using Sass.
    </>,
  ],
};

const frontendLead = {
  title: 'Front-end Lead & Front-end Architect',
  location: 'Prague, CZ',
  from: '2015-12-31',
  to: '2016-08-31',
  technologies: ['javascript', 'react', 'nodejs'],
  description: [
    'Leading the team of front-end developers.',
    'Worked on planning, estimating and architectural decisions around new front-end features.',
    'Optimized processes on front-end development teams.',
    'Participated daily in code reviews for the team.',
    'Developed a design system of reusable components to speed up development and introduce consistency into the codebase (both programmatically and visually), cooperating with the UX team.',
    'Helped with integrating Agile processes.',
    <>
      Continued contributing to the{' '}
      <Anchor
        href="https://app.isportlife.cz/welcome"
        target="_blank"
        rel="noopener"
      >
        Superlife
      </Anchor>{' '}
      codebase and worked on improving performance of the web application.
    </>,
  ],
};

const czechnewscenter = {
  id: 'work-experience-cnc',
  company: {
    name: 'CZECH NEWS CENTER a.s.',
    url: 'https://www.cncenter.cz/en',
    description:
      'The largest media house with highest number of readers at Czech market.',
    logo,
    headquarters: 'Prague, CZ',
    technologies: [
      'javascript',
      'jquery',
      'angularjs',
      'html',
      'css',
      'sass',
      'grunt',
      'xml',
      'xslt',
    ],
  },
  positions: [frontendDev, frontendLead],
};

export default czechnewscenter;
