import React from 'react';
import SEO from '../components/seo';
import { Box, Heading, Text, Image, Button } from 'grommet';
// import { format, differenceInCalendarMonths } from 'date-fns';
import jobs from '../data/jobs';
import educationList from '../data/education';
import { JobsList } from '../components/jobs/JobsList';
import { JobListItem } from '../components/jobs/JobListItem';
import ResumeSection from '../sections/ResumeSection';
import EducationList from '../components/education/EducationList';
import Layout from '../components/Layout';
import { getThemeColor } from '../theme';
import resumeIllustration from '../images/illustrations/personal_data.svg';
import { useStaticQuery, graphql } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const ResumePage = () => {
  const { file: resumePdfFile } = useStaticQuery(
    graphql`
      query {
        file(
          sourceInstanceName: { eq: "files" }
          relativePath: { eq: "resume_lukas_horak.pdf" }
        ) {
          publicURL
        }
      }
    `
  );

  return (
    <Layout>
      <SEO title="Resume" />
      {/* <PageTitle title="Resume" /> */}
      <Box
        css={`
          background: ${getThemeColor('brand-dark-blue')};
          color: ${getThemeColor('brand-light-gray')};
        `}
        align="center"
        pad="large"
      >
        <Box width="small">
          <Image
            src={resumeIllustration}
            fill={false}
            alt="Resume Illustration Image"
            a11yTitle="Resume Illustration Image"
          />
        </Box>
        <Box align="center">
          <Heading level="1">RESUMÉ</Heading>
          <Text textAlign="center">
            What I've been up to in my professional life
          </Text>
        </Box>
        <Box align="center">
          <Button
            label="Download PDF version"
            href={resumePdfFile.publicURL}
            download
            css={`
              color: ${getThemeColor('brand-light-gray')};
              font-weight: 600;
              margin-top: 48px;
            `}
            target="_blank"
            rel="noopener"
            onClick={() => {
              trackCustomEvent({
                category: 'pdf_resume',
                action: 'download',
                label: 'download_resume',
              });
            }}
          />
        </Box>
      </Box>
      <Box width="full" pad="small">
        <Box
          width="xlarge"
          css={`
            margin: 0 auto 1rem auto;
          `}
        >
          <Box gridArea="resumeContent">
            <ResumeSection
              as="section"
              a11yTitle="Work Experience section"
              sectionName="Work Experience"
            >
              <JobsList>
                {jobs.map(job => (
                  <JobListItem job={job} key={job.id} />
                ))}
              </JobsList>
            </ResumeSection>
            <ResumeSection
              as="section"
              a11yTitle="Education section"
              sectionName="Education"
            >
              <EducationList items={educationList} />
            </ResumeSection>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default ResumePage;
