import logo from '../../images/ctu_logo.svg';

const ctu = {
  name: 'Czech Technical University in Prague',
  degree: `Bachelor's degree`,
  link: 'https://www.cvut.cz/en/',
  logo,
  from: '2011-01-01',
  to: '2017-12-31',
  fieldOfStudy: 'Web Page, Digital/Multimedia and Information Resources Design',
  description:
    'Attended following courses: Automata and Grammars, Computer Networks, Computer Security, Computer Structure and Architecture, Database Systems, Electronic Documentation Design, Introduction to Operating System UNIX, Introduction to Software Engineering, Multimedia and Graphics Applications, Operating Systems, Programming and Algorithmics 1 & 2, Programming in Java, Project management, Searching the Web and Multimedia Databases, User Interface Design, Web Technologies 1 & 2, and others',
};

export default ctu;
