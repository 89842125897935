import React from 'react';
// import {Anchor} from 'grommet';

import SSCLogo from '../../images/companies_logos/ssc_200x200.svg';
import { Anchor } from 'grommet';

const posLeadDev = {
  title: 'Sr. Software Engineer (FULL-STACK)',
  location: 'Prague, CZ',
  from: '2019-01-01',
  to: '2019-07-01',
  technologies: ['javascript', 'react', 'nodejs'],
  description: [
    <>
      Developed new fatures and maintained (someone has to squash those bugs
      right?) the&nbsp;SecurityScorecard platform.
    </>,
    'Developed and maintained APIs for the in house SPA and public APIs. (NodeJS)',
    <>
      Implemented automatic API testing using{' '}
      <Anchor
        href="https://dredd.org/en/latest/"
        target="_blank"
        rel="noopener"
      >
        Dredd testing framework
      </Anchor>
      , which runs against{' '}
      <Anchor
        href="https://swagger.io/docs/specification/about/"
        target="_blank"
        rel="noopener"
      >
        Swagger (OpenAPI)
      </Anchor>
      .
    </>,
    'Migrated the platform to use latest React to enable use of new features such as Hooks and to get rid of technical debt.',
    'Contributing to the main codebase using React, Redux, Functional Programming.',
  ],
};

const posSrDev = {
  title: 'Front-end Manager, Squad Tech Lead',
  location: 'Prague, CZ',
  from: '2019-07-01',
  to: new Date().toISOString(),
  technologies: ['javascript', 'react', 'nodejs'],
  description: [
    'Managing the Prague front-end team of 8 engineers.',
    'Tech Lead for two squads which are mainly front-end focused.',
    'Participating in architectural decisions around the front-end and APIs.',
    'Helping Product Management with finding solutions for new features, identifying dependencies across the company, estimating development time.',
    <>
      Certified as{' '}
      <Anchor
        href="https://www.youracclaim.com/badges/aa3f2a15-0ea6-4fe1-970a-2b17122e6b53/linked_in_profile"
        target="_blank"
        rel="noopener"
      >
        SAFe 4 Practitioner
      </Anchor>
    </>,
    'Optimizing processes so that developers can code instead of spending hours on meetings.',
    'Improving the deployment processes (aiming at continuous delivery).',
  ],
};

const securityscorecard = {
  id: 'work-experience-ssc',
  company: {
    name: 'SecurityScorecard',
    url: 'https://securityscorecard.com',
    description:
      'SecurityScorecard is the global leader in cybersecurity ratings and the only service with over a million companies continuously rated. SecurityScorecard’s patented rating technology is used by over 1,000 organizations for self-monitoring, third-party risk management, board reporting and cyber insurance underwriting; making all organizations more resilient by allowing them to easily find and fix cybersecurity risks across their externally facing digital footprint.',
    logo: SSCLogo,
    headquarters: 'New York, U.S.',
    technologies: [
      'javascript',
      'react',
      'redux',
      'ramda',
      'jest',
      'cypress',
      'nodejs',
      'styledcomponents',
      'css',
      'sass',
      'storybook',
      'docker',
      'mongodb',
      'postgres',
      'jenkins',
      'aws',
      'vscode',
    ],
  },
  positions: [posLeadDev, posSrDev],
};

export default securityscorecard;
