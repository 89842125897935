import React from 'react';
import { Box, Anchor, Heading, ResponsiveContext } from 'grommet';
import { parseISO, getYear } from 'date-fns/fp';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { getThemeColor } from '../../theme';
import { pipe } from 'ramda';

const parseAndGetYear = pipe(parseISO, getYear);

const titleStyle = css`
  text-decoration: none;
  outline: none;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${getThemeColor('dark-1')};
  transition: all 150ms ease-out;
  display: flex;
  line-height: 2rem;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;

  &:focus,
  &:hover {
    outline: none;
    border: none;
    box-shadow: none;
    text-decoration: none;
  }

  &:active {
    box-shadow: 0 0 4px -1px ${getThemeColor('brand-gray')};
  }

  &:hover {
    color: ${getThemeColor('brand')};
  }
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
`;

const EducationListItem = ({ data }) => {
  const {
    name,
    degree,
    from,
    to,
    fieldOfStudy,
    description,
    logo,
    link,
  } = data;
  const currentPageSize = React.useContext(ResponsiveContext);
  return (
    <ListItem>
      <Box direction="row">
        {logo && (
          <Box
            width={{ min: '50px', max: '50px' }}
            direction="row"
            justify="center"
            align="center"
            css={`
              margin-right: 1rem;
            `}
          >
            <img
              alt={`${name} logo`}
              src={logo}
              css={css`
                width: 40px;
                line-height: 2rem;
              `}
            />
          </Box>
        )}
        <Box direction="column" width="full">
          {link ? (
            <Anchor href={link} target="_blank" rel="noopener" css={titleStyle}>
              <Heading level="2" css={titleStyle}>
                {name}
              </Heading>
            </Anchor>
          ) : (
            <Heading level="2" css={titleStyle}>
              {name}
            </Heading>
          )}
          {fieldOfStudy && <span>{fieldOfStudy}</span>}
        </Box>
      </Box>
      <Box
        margin={{
          left: 'calc(50px + 1rem)',
        }}
      >
        <Box
          direction={currentPageSize === 'small' ? 'column' : 'row'}
          css={`
            color: ${getThemeColor('dark-2')};
          `}
        >
          <span
            css={`
              margin-right: 1rem;
            `}
          >
            <FontAwesomeIcon icon={faCalendarAlt} />
            &nbsp;{parseAndGetYear(from)} - {parseAndGetYear(to)}
          </span>
          {degree && (
            <span
              css={`
                margin-right: 1rem;
              `}
            >
              <FontAwesomeIcon icon={faAward} /> {degree}
            </span>
          )}
        </Box>
        {description && <p>{description}</p>}
      </Box>
    </ListItem>
  );
};

export default EducationListItem;
