import React from 'react';

import logo from '../../images/logo_small_dark.svg';
import { Anchor } from 'grommet';

const webDev = {
  title: 'Web Developer',
  location: 'Prague, CZ',
  from: '2012-01-01',
  to: '2014-10-31',
  technologies: ['wordpress', 'html', 'css', 'javascript', 'jquery'],
  description: [
    'Worked as a freelance developer building websites',
    'Mainly worked with HTML5, CSS, JavaScript, and jQuery.',
    'Built websites based on Wordpress CMS, both creating custom built templates and modifying existing ones to customers needs.',
    'Worked partially in graphic designing as well (both web and print).',
    <>
      Examples of work:{' '}
      <Anchor href="http://bumpergames.cz/" target="_blank" rel="noopener">
        BumperGames
      </Anchor>
      ,{' '}
      <Anchor href="http://zubni-literak.cz/" target="_blank" rel="noopener">
        Zubni-Literak
      </Anchor>
      ,{' '}
      <Anchor href="https://polnonakup.sk/" target="_blank" rel="noopener">
        Polnonakup Liptov
      </Anchor>
      ,{' '}
      <Anchor href="http://opravnasos.cz/" target="_blank" rel="noopener">
        Opravna SOS
      </Anchor>
      , and others...
    </>,
  ],
};

const independentcontractor = {
  id: 'work-experience-independent-contractor',
  company: {
    name: 'Independent Contractor',
    url: 'https://lukashorak.dev',
    description:
      'Working as a freelancer on projects based mainly on Wordpress CMS, or written in pure HTML + CSS + JS.',
    logo,
    headquarters: 'Prague, CZ',
    technologies: [
      'javascript',
      'jquery',
      'html',
      'css',
      'sass',
      'wordpress',
      'php',
    ],
  },
  positions: [webDev],
};

export default independentcontractor;
