import securityscorecard from './securityscorecard';
import gooddata from './gooddata';
import czechnewscenter from './czechnewscenter';
import independentcontractor from './independentcontractor';
import macpodpora from './macpodpora';

const jobsList = [
  securityscorecard,
  gooddata,
  czechnewscenter,
  independentcontractor,
  macpodpora,
];

export default jobsList;
