import logo from '../../images/iso_logo.png';

const iso = {
  name: '1st International School of Ostrava',
  degree: 'Final State and School Exams',
  link: 'https://www.is-ostrava.cz/en/',
  logo,
  from: '2006-01-01',
  to: '2011-09-01',
  fieldOfStudy: 'General Education',
  description: `Graduated with honors from Mathematics(A), Physics(A), Czech language(A), and English Language(A). Attended the Copernicus Exchange Project in Ireland (presenting the Czech Republic)`,
};

export default iso;
