import React from 'react';
import PropTypes from 'prop-types';
import EducationListItem from './EducationListItem';
import styled from 'styled-components';

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const EducationList = ({ items }) => {
  return (
    <List>
      {items.map((item, index) => (
        <EducationListItem key={index} data={item} />
      ))}
    </List>
  );
};

EducationList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default EducationList;
