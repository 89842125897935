import React from 'react';
import { Anchor } from 'grommet';

import logo from '../../images/companies_logos/gd_200x200.svg';

const posSrDev = {
  title: 'Sr. Software Engineer (UI)',
  location: 'Prague, CZ',
  from: '2016-09-01',
  to: '2018-12-31',
  technologies: ['javascript', 'react', 'nodejs'],
  description: [
    `Worked on front-end of two main components of the GoodData SPA platform for big data analytics. Both solutions are written in React with Redux state management. We've rewritten the codebase to TypeScript.`,
    <>
      Worked on creating an open source React.js SDK to help developers
      integrate GoodData analytics into their own solutions. Written in
      TypeScript. You can have a look at{' '}
      <Anchor
        href="https://github.com/gooddata/gooddata-react-components/"
        title="GoodData UI SDK"
        target="_blank"
        rel="noopener"
      >
        the source code of GoodData.UI framework
      </Anchor>
      .
    </>,
    <>
      Worked on delivering a documentation for the GoodDataUI SDK using{' '}
      <Anchor
        href="https://docusaurus.io/"
        title="Docusaurus documentation framework"
        target="_blank"
        rel="noopener"
      >
        Docusaurus
      </Anchor>
      . Have a look at the{' '}
      <Anchor
        href="https://sdk.gooddata.com/gooddata-ui/docs/about_gooddataui.html"
        target="_blank"
        rel="noopener"
      >
        GoodDataUI documentation
      </Anchor>
      , or read through the{' '}
      <Anchor
        href="https://github.com/gooddata/gooddata-ui"
        target="_blank"
        rel="noopener"
      >
        source code
      </Anchor>
      .
    </>,
    <>
      Worked on implementation of component and end-to-end testing for the UI
      applications using{' '}
      <Anchor
        href="https://github.com/DevExpress/testcafe"
        title="TestCafe end-to-end web testing framework"
        target="_blank"
        rel="noopener"
      >
        TestCafe
      </Anchor>
    </>,
    <>
      Helped with creating a{' '}
      <Anchor
        href="https://github.com/crudo/grunt-testcafe"
        target="_blank"
        rel="noopener"
      >
        Grunt TestCafe
      </Anchor>{' '}
      runner.
    </>,
  ],
};

const gooddata = {
  id: 'work-experience-gooddata',
  company: {
    name: 'GoodData',
    url: 'https://gooddata.com',
    description: '',
    logo,
    headquarters: 'San Francisco, U.S.',
    technologies: [
      'javascript',
      'typescript',
      'react',
      'redux',
      'nodejs',
      'jest',
      'testcafe',
      'lodash',
      'html',
      'css',
      'sass',
      'storybook',
      'jenkins',
      'vscode',
    ],
  },
  positions: [posSrDev],
};

export default gooddata;
