import logo from '../../images/companies_logos/mp_200x200.svg';

const userSupportSpecialist = {
  title: 'User Support Specialist',
  location: 'Prague, CZ',
  from: '2012-10-01',
  to: '2013-12-31',
  technologies: [],
  description: [
    'Worked with clients, helping them fix problems that they had with their Apple devices.',
    'Worked on setting up Mac OS, iOS and other Apple products for customers.',
    'Helped setting up home connected systems, provided advice on buying, setting up, or configuring devices.',
  ],
};

const macpodpora = {
  id: 'work-experience-macpodpora',
  company: {
    name: 'MacPodpora',
    url: 'https://macpodpora.cz',
    description:
      'Company with a first class support for users and companies that are using Apple products and need a help.',
    logo,
    headquarters: 'Prague, CZ',
  },
  positions: [userSupportSpecialist],
};

export default macpodpora;
