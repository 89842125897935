import React from 'react';
import styled, { css } from 'styled-components';
import {
  max,
  min,
  parseISO,
  format,
  differenceInCalendarMonths,
} from 'date-fns/fp';
import { Anchor, Heading, Box, ResponsiveContext } from 'grommet';
import { map, pipe } from 'ramda';
import { isArray, isString } from 'ramda-adjunct';
import { getThemeColor } from '../../theme';
import { TechBadge } from '../TechBadge';
import { darken, lighten } from 'polished';
import { faBuilding, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JobDescriptionList from './JobDescriptionList';

const CompanyInfo = ({
  title,
  url,
  logo,
  description,
  duration,
  from,
  to,
  technologies,
  headquarters,
}) => {
  const titleStyle = css`
    text-decoration: none;
    outline: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: ${getThemeColor('dark-1')};
    transition: all 150ms ease-out;
    display: flex;
    line-height: 2rem;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;

    &:focus,
    &:hover {
      outline: none;
      border: none;
      box-shadow: none;
      text-decoration: none;
    }

    &:active {
      box-shadow: 0 0 4px -1px ${getThemeColor('brand-gray')};
    }

    &:hover {
      color: ${getThemeColor('brand')};
    }
  `;
  const currentPageSize = React.useContext(ResponsiveContext);

  if (url) {
    return (
      <>
        <Box direction="row">
          {logo && (
            <Box
              width={{ min: '50px', max: '50px' }}
              direction="row"
              justify="center"
              align="center"
              css={`
                margin-right: 1rem;
              `}
            >
              <img
                alt={`${title} logo`}
                src={logo}
                css={css`
                  width: 40px;
                  line-height: 2rem;
                `}
              />
            </Box>
          )}
          <Box direction="column" width="full">
            <Anchor
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              css={titleStyle}
            >
              <Heading level="2" css={titleStyle}>
                {title}
              </Heading>
            </Anchor>
            <Box
              direction={currentPageSize === 'small' ? 'column' : 'row'}
              css={`
                color: ${getThemeColor('dark-2')};
              `}
            >
              <span
                css={`
                  margin-right: 1rem;
                `}
              >
                <FontAwesomeIcon icon={faCalendarAlt} />
                &nbsp;
                {formatDateForDisplay(from)} - {formatDateForDisplay(to)} •{' '}
                {duration}
              </span>
              <span>
                <FontAwesomeIcon icon={faBuilding} />
                &nbsp;{headquarters}
              </span>
            </Box>
          </Box>
        </Box>
        <Box
          margin={{
            left: currentPageSize === 'small' ? '0' : 'calc(50px + 1rem)',
          }}
        >
          <p>{description}</p>
          {technologies && (
            <Box
              width="full"
              css={`
                margin-bottom: 1rem;
              `}
            >
              <Box
                width="full"
                css={`
                  font-weight: 600;
                  color: ${getThemeColor('brand-gray')};
                `}
              >
                TECH STACK
              </Box>
              <Box
                direction="row"
                width="full"
                css={`
                  /* /* border-bottom: 1px solid
                    ${darken(0.15, getThemeColor('brand-light-gray'))}; */
                  border-top: 1px solid
                    ${darken(0.15, getThemeColor('brand-light-gray'))};
                    display: inline-block;
                `}
              >
                {technologies.map((tech, index) => (
                  <TechBadge tech={tech} key={index} />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  }

  return (
    <>
      {logo && (
        <img
          alt={`${title} logo`}
          src={logo}
          css={css`
            height: 2rem;
            width: 2rem;
            line-height: 2rem;
          `}
        />
      )}
      <Heading level="2" css={titleStyle}>
        {title}
      </Heading>
    </>
  );
};

const getJobDuration = (from, to) => {
  const monthsInJob = differenceInCalendarMonths(from, to);
  const months = monthsInJob % 12;
  const years = Math.floor(monthsInJob / 12);

  return `${years > 0 ? years : ''}${
    years > 0 ? (years > 1 ? ' yrs' : ' yr') : ''
  } ${months > 0 ? months : ''}${
    months > 0 ? (months > 1 ? ' mos' : ' mo') : ''
  }`;
};

const formatDateForDisplay = date => {
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  const dateMonth = date.getMonth();
  const dateYear = date.getFullYear();

  if (currentMonth === dateMonth && currentYear === dateYear) {
    return 'Present';
  }

  return format('MMM yyyy', date);
};

const getEarliestDate = pipe(
  map(parseISO),
  min
);

const getLatestDate = pipe(
  map(parseISO),
  max
);

const ItemContainer = styled.li`
  padding: 0 0 1.5rem 0;
  /* border-left: 1px solid gray; */
  position: relative;
  /* &::before {
    content: '•';
    position: absolute;
    left: -0.45rem;
    top: 1rem;
    font-size: 1.5rem;
    color: gray;
    line-height: 3rem;
  } */
`;

const Positions = ({ positions }) => {
  const currentPageSize = React.useContext(ResponsiveContext);

  return (
    <>
      {positions
        .sort(
          (prevPos, currentPos) =>
            parseISO(currentPos.from) - parseISO(prevPos.from)
        )
        .map(({ from, to, title, description, location }, index) => (
          <Box
            key={index}
            direction="row"
            margin={{ bottom: '1rem' }}
            width="full"
          >
            {currentPageSize !== 'small' && (
              <Box
                width={{ min: '50px', max: '50px' }}
                alignContent="center"
                direction="row"
                justify="center"
                css={`
                  margin-right: 1rem;
                `}
              >
                <Box
                  css={`
                    width: 0px;
                    box-shadow: 0px 0 0px 1px
                      ${lighten(0.7, getThemeColor('brand-dark-blue'))};
                    margin-top: 40px;
                  `}
                >
                  <FontAwesomeIcon
                    icon={faCircle}
                    css={`
                      position: relative;
                      font-size: 8px;
                      left: -4px;
                      top: -32px;
                      width: 8px;
                      color: ${lighten(0.6, getThemeColor('brand-dark-blue'))};
                    `}
                  />
                  &nbsp;
                </Box>
              </Box>
            )}
            <Box>
              <span
                css={`
                  font-size: 0.95rem;
                  font-weight: 600;
                  text-transform: uppercase;
                  margin-bottom: 0.5rem;
                  color: ${getThemeColor('brand-dark-gray')};
                `}
              >
                {title}
              </span>
              <Box
                direction={currentPageSize === 'small' ? 'column' : 'row'}
                css={`
                  color: ${getThemeColor('dark-2')};
                  font-size: ${currentPageSize === 'small' ? '14px' : '0.9rem'};
                `}
              >
                <span
                  css={`
                    color: ${getThemeColor('dark-2')};
                    margin-right: 1rem;
                  `}
                >
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  &nbsp;
                  {formatDateForDisplay(parseISO(from))} -{' '}
                  {formatDateForDisplay(parseISO(to))} •{' '}
                  {getJobDuration(parseISO(from), parseISO(to))}
                </span>
                <span>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  &nbsp;{location}
                </span>
              </Box>
              {isArray(description) && (
                <JobDescriptionList descriptionItems={description} />
              )}
              {isString(description) && <p>{description}</p>}
            </Box>
          </Box>
        ))}
    </>
  );
};

export const JobListItem = ({ job }) => {
  const { company, id, positions } = job;
  const earliestDate = getEarliestDate(
    positions.map(position => position.from)
  );
  const latestDate = getLatestDate(positions.map(position => position.to));
  const duration = getJobDuration(earliestDate, latestDate);

  const { name, url, logo, description, technologies, headquarters } = company;

  return (
    <ItemContainer id={id}>
      <CompanyInfo
        title={name}
        url={url}
        logo={logo}
        description={description}
        duration={duration}
        from={earliestDate}
        to={latestDate}
        technologies={technologies}
        headquarters={headquarters}
      />
      <Positions positions={positions} />
    </ItemContainer>
  );
};
