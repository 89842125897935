import React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';

const JobsContainer = styled(Box)``;

const JobsListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 0;
`;

export const JobsList = ({ children }) => {
  return (
    <JobsContainer>
      <JobsListContainer>{children}</JobsListContainer>
    </JobsContainer>
  );
};
