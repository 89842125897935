import React from 'react';
import styled from 'styled-components';

const ListContainer = styled.ul`
  padding: 0;
  margin-top: 1rem;
`;

const ListItem = styled.li`
  list-style: none;
  padding: 0 0 0 1rem;
  margin-bottom: 0.5rem;

  &::before {
    content: '•';
    display: inline-block;
    margin-left: -1rem;
    width: 1rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const JobDescriptionList = ({ descriptionItems }) => {
  return (
    <ListContainer>
      {descriptionItems.map((item, index) => (
        <ListItem key={index}>{item}</ListItem>
      ))}
    </ListContainer>
  );
};

export default JobDescriptionList;
